import axios from "axios";
import { apiEndpoint } from "../utils/variables";

export const updateWeldBubble = async (fileId, weldBubble) => {
    const response = axios.post(apiEndpoint + "api/db/update-weld-bubble/", {
        fileId: fileId,
        weldBubble: weldBubble
    })
    return response
}

export const getWeldBubble = async (fileId) => {
    const response = axios.post(apiEndpoint + "api/db/get-weld-bubble/", {
        fileId: fileId,
    })
    return response
}

export const getUser = async (email) => {
    const response = axios.post(apiEndpoint + "api/db/get-user/", {
        email: email,
    })
    return response
}

export const getIsProjectDeleted = async (folderId) => {
    const response = axios.post(apiEndpoint + "api/db/get-is-project-deleted/", {
        folderId: folderId,
    })
    return response
}