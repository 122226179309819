export const HexagonSvg = (width, height) => {
    return `
     <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="${height}" 
     preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="8853bbb4da"><path d="M 373.8125 189.875 L 282.4375 372.625 C 281.710938 374.082031 280.222656 
     375 278.597656 375 L 96.402344 375 C 94.777344 375 93.289062 374.082031 92.5625 372.625 L 1.1875 189.875 C 0.441406 188.378906 0.441406 186.621094 1.1875 185.125 L 
     92.5625 2.375 C 93.289062 0.917969 94.777344 0 96.402344 0 L 278.597656 0 C 280.222656 0 281.710938 0.917969 282.4375 2.375 L 373.8125 185.125 C 374.558594 186.621094 
     374.558594 188.378906 373.8125 189.875 Z M 373.8125 189.875 " clip-rule="nonzero"/></clipPath><clipPath id="fc27d835e8"><path d="M 0 0.00390625 L 374.988281 0.00390625 
     L 374.988281 375 L 0 375 Z M 0 0.00390625 " clip-rule="nonzero"/></clipPath><clipPath id="28d54f6bcb"><path d="M 373.800781 189.875 L 282.429688 372.621094 C 281.699219 
     374.074219 280.214844 374.996094 278.585938 374.996094 L 96.402344 374.996094 C 94.773438 374.996094 93.289062 374.074219 92.558594 372.621094 L 1.1875 189.875 C 
     0.441406 188.378906 0.441406 186.621094 1.1875 185.125 L 92.558594 2.378906 C 93.289062 0.925781 94.773438 0.00390625 96.402344 0.00390625 L 278.585938 0.00390625 
     C 280.214844 0.00390625 281.699219 0.925781 282.429688 2.378906 L 373.800781 185.125 C 374.546875 186.621094 374.546875 188.378906 373.800781 189.875 Z M 373.800781 
     189.875 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#8853bbb4da)"><rect x="-37.5" width="450" fill="#ffffff" y="-37.499999" height="449.999989" 
     fill-opacity="1"/></g><g clip-path="url(#fc27d835e8)"><g clip-path="url(#28d54f6bcb)"><path stroke-linecap="butt" transform="matrix(0.75, 0, 0, 0.75, 0.000076275, 
        0.00581467)" fill="none" stroke-linejoin="miter" d="M 498.400973 253.15893 L 376.57284 496.820405 C 375.598881 498.757905 373.619715 499.987072 371.447839 499.987072 
        L 128.536365 499.987072 C 126.36449 499.987072 124.385323 498.757905 123.411365 496.820405 L 1.583232 253.15893 C 0.58844 251.164139 0.58844 248.820388 1.583232 
        246.825597 L 123.411365 3.164122 C 124.385323 1.226622 126.36449 -0.00254456 128.536365 -0.00254456 L 371.447839 -0.00254456 C 373.619715 -0.00254456 375.598881 
        1.226622 376.57284 3.164122 L 498.400973 246.825597 C 499.395764 248.820388 499.395764 251.164139 498.400973 253.15893 Z M 498.400973 253.15893 " stroke="#ff0000" 
        stroke-width="24" stroke-opacity="1" stroke-miterlimit="4"/></g></g></svg>
    `
}