import "../styles/pdfStyles.css";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  memo,
  useTransition,
} from "react";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { PentagonSvg } from "../assets/pentagon.svg";
import { CircleSvg } from "../assets/circle.svg";
import { HexagonSvg } from "../assets/hexagon.svg";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  apiEndpoint,
  touchduration,
  defPageScale,
  defSvgSize,
  deftextSize,
} from "../utils/variables";
import { getWeldBubble, updateWeldBubble } from "../api/dbApi";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedBubble } from "../actions/pdfDoc";
import Modal from "../components/Modal";
import Spinner from "react-activity/dist/Spinner";
import ToolBar from "../components/ToolBar";
//eslint-disable-next-line

let timer;
let pendingPageNum = null;
let pendingLineDraw = false;
let isDrawing = false;
let mousePos = { x: 0, y: 0 };
let lastPos = { x: 0, y: 0 };
let drawableCanvasRef = [];
const text = {
  default: {
    type: "",
    heading: "",
    subHeading: "",
    message: "",
  },
  Sync: {
    type: "Sync",
    heading: "Sync",
    subHeading: "Are you sure you want to continue?",
    message: "This will sync all the mapped/deleted weld bubbles",
  },
  moreThanOneBubble: {
    type: "moreThanOneBubble",
    heading: "Bubble with same ID",
    subHeading: "",
    message: "You cant make more than one bubble with a same weld name",
  },
};

const ICEditor = () => {
  const [pdfRef, setPdfRef] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageWidth, setPageWidth] = useState();
  const [pageHeight, setPageHeight] = useState();
  const [isRendering, setIsRendering] = useState(false);
  const [pageRotation, setPageRotation] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [doc, setDoc] = useState({});
  const [pages, setPages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userBubble, setUserBubble] = useState([]);
  const [weldBServer, setWeldBServer] = useState([]);
  const [weldBubble, setWeldBubble] = useState([]);
  const [newState, setNewState] = useState([]);
  const [textSize, setTextSize] = useState(deftextSize);
  const [svgSize, setSvgSize] = useState(
    defSvgSize + searchParams.get("weldname").length * 2
  );
  const [edit, setEdit] = useState( 
    searchParams.get("edit")
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedShape, setSelectedShape] = useState("pentagonSvg");
  const [modalType, setModalType] = useState("default");
  const [listening, setListening] = useState(false);
  const [currentCanvas, setCurrentCanvas] = useState(0);
  const [stringBuffer, setStringBuffer] = useState(null);
  const [isTouchLock, setIsTouchLock] = useState(false);
  const [pageScale, setPageScale] = useState(defPageScale);
  const [initialPinchDistance, setInitialPinchDistance] = useState(0);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [pinchScale, setPinchScale] = useState(1);
  const [isPending, startTransition] = useTransition();
  const [isBTPending, startBTransition] = useTransition();
  const shape = useSelector((state) => state.doc.selectedBubble);
  const dispatch = useDispatch();
  const canvasRef = useRef();
  const bubbleRef = useRef();
  const isMountingRef = useRef(false);
  const [temp, setTemp] = useState();

  let weldName = searchParams.get("weldname");
  let user = searchParams.get("email");

  bubbleRef.current = weldBubble;

  /**
   * Initialize Render
   *          |
   *          v
   */
  pdfjsLib.GlobalWorkerOptions.workerSrc =
    window.location.origin + "/pdf.worker.min.js";

  const renderPdf = async () => {
    const loadingTask = pdfjsLib.getDocument(
      `${apiEndpoint}api/serve-pdf-range?fileid=${searchParams.get("fileid")}`
    );
    loadingTask.promise.then(
      (loadedPdf) => {
        createCanvases(loadedPdf.numPages);
        renderPage(currentPage, loadedPdf);
        setPdfRef(loadedPdf);
      },
      function (reason) {
        console.error(reason);
      }
    );

    const initState = async (pdfdoc) => {
      const pdfPages = pdfdoc.getPages();
      const page = pdfPages[currentPage - 1];
      const { width, height } = page.getSize();
      startTransition(() => {
        setDoc(pdfdoc);
      });
      setPages(pdfPages);
      pdfdoc.save();
    };

    const createCanvases = (count) => {
      const newdrawableCRefs = Array.from({ length: count }, () => ({
        ref: React.createRef(),
      }));
      drawableCanvasRef = newdrawableCRefs;
    };
  };

  const initDrawingCanvas = (width, height) => {
    drawableCanvasRef.forEach(({ ref, context }, index) => {
      const canvas = ref.current;
      canvas.height = height;
      canvas.width = width;
    });
    initOldBubblesDb();
  };

  const renderPage = useCallback(
    (pageScale, pdf = pdfRef) => {
      pdf.getPage(currentPage).then((page) => {
        const rotation = page._pageInfo.rotate;
        setPageRotation(rotation);
        setIsRendering(true);
        const canvas = canvasRef.current;
        const viewport = page.getViewport({ scale: pageScale });
        let height =
          rotation === 270
            ? page.view[2] * pageScale
            : rotation === 90
            ? page.view[2] * pageScale
            : page.view[3] * pageScale;
        let width =
          rotation === 270
            ? page.view[3] * pageScale
            : rotation === 90
            ? page.view[3] * pageScale
            : page.view[2] * pageScale;
        canvas.height = height;
        canvas.width = width;
        setPageWidth(width);
        setPageHeight(height);
        initDrawingCanvas(width, height);
        const renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
        };

        const renderTask = page.render(renderContext);
        renderTask.promise.then(() => {
          setIsRendering(false);
        });
      });
    },
    [pdfRef, currentCanvas]
  );

  /**
   * EventListners / handle Interaction
   *          |
   *          v
   */

  const mouseDown = (e) => {
    if (e.type !== "touchstart" && edit === 'true') {
      getTouchCoordinates(e);
      drawWeldBubble();
    }
  };

  const mouseUp = (e) => {
    isDrawing = false;
  };

  const touchStart = (e) => {
    const { top, left } =
      drawableCanvasRef[currentPage - 1].ref.current.getBoundingClientRect();

    if (e.touches.length > 1) {
      const clientX = [e.touches[0].clientX, e.touches[1].clientX];
      const clientY = [e.touches[0].clientY, e.touches[1].clientY];
      setStartX((clientX[0] - left + (clientX[1] - left)) / 2);
      setStartY((clientY[0] - top + (clientY[1] - top)) / 2);
      setInitialPinchDistance(
        Math.hypot(
          clientX[1] - left - (clientX[0] - left),
          clientY[1] - top - (clientY[0] - top)
        )
      );
    }
    if(edit === 'true'){
        getTouchCoordinates(e);
        drawWeldBubble();
    } 
  };

  const touchEnd = (e) => {
    isDrawing = false;
  };

  const touchMove = (e) => {
    isDrawing = false;
    if (initialPinchDistance <= 0 || e.touches.length < 2) {
      return;
    }
    if (e.scale !== 1) {
      e.preventDefault();
    }
    initPinchZoom(e);
  };

  let animationFrameId = null;

  const initPinchZoom = (e) => {
    const { top, left } =
      drawableCanvasRef[currentPage - 1].ref.current.getBoundingClientRect();
    const clientX = [e.touches[0].clientX, e.touches[1].clientX];
    const clientY = [e.touches[0].clientY, e.touches[1].clientY];
    const pinchDistance = Math.hypot(
      clientX[1] - left - (clientX[0] - left),
      clientY[1] - top - (clientY[0] - top)
    );
    const pinchScale = pinchDistance / initialPinchDistance;

    cancelAnimationFrame(animationFrameId);
    animationFrameId = requestAnimationFrame(() => {
      applyTransform(pinchScale);
    });
  };

  const applyTransform = (scale) => {
    const originX = startX + canvasRef.current.scrollLeft;
    const originY = startY + canvasRef.current.scrollTop;
    canvasRef.current.style.transform = `scale(${scale})`;
    canvasRef.current.style.transformOrigin = `${originX}px ${originY}px`;
    drawableCanvasRef[
      currentPage - 1
    ].ref.current.style.transform = `scale(${scale})`;
    drawableCanvasRef[
      currentPage - 1
    ].ref.current.style.transformOrigin = `${originX}px ${originY}px`;
  };

  const reset = () => {
    if (initialPinchDistance <= 0) {
      return;
    }

    cancelAnimationFrame(animationFrameId);
    animationFrameId = requestAnimationFrame(() => {
      applyTransform(1); // Reset to original scale
    });

    // Adjust scroll position based on pinch scale
    const rect = canvasRef.current.getBoundingClientRect();
    const dx = startX - rect.left;
    const dy = startY - rect.top;
    canvasRef.current.scrollLeft += dx * (pinchScale - 1);
    canvasRef.current.scrollTop += dy * (pinchScale - 1);
    drawableCanvasRef[currentPage - 1].ref.current.scrollLeft +=
      dx * (pinchScale - 1);
    drawableCanvasRef[currentPage - 1].ref.current.top += dx * (pinchScale - 1);
  };

  const getTouchCoordinates = (e) => {
    const rect =
      drawableCanvasRef[currentPage - 1].ref.current.getBoundingClientRect();
    const canvas = drawableCanvasRef[currentPage - 1].ref.current;
    const ctx = canvas.getContext("2d");
    let scaleX = 1;
    let scaleY = 1;

    if (canvas.style.transform) {
      const scaleMatch = canvas.style.transform.match(/scale\(([^)]+)\)/);
      if (scaleMatch && scaleMatch[1]) {
        const scaleValues = scaleMatch[1].split(",");
        scaleX = parseFloat(scaleValues[0]);
        scaleY = parseFloat(scaleValues[1] || scaleValues[0]);
      }
    }
    if (e.type === "touchstart") {
      const x = (e.touches[0].clientX - rect.left) / scaleX;
      const y = (e.touches[0].clientY - rect.top) / scaleY;
      mousePos = { x, y };
    } else {
      // handle click for mouse pointer, there will be no pinch scale
      const x = e.nativeEvent.clientX - rect.left;
      const y = e.nativeEvent.clientY - rect.top;
      mousePos = { x, y };
    }
  };

  const driveSyncWorker = () => {
    const myWorker = new Worker("./worker.js");
    let canvasToArrayBuffer = [];

    drawableCanvasRef.forEach(async (ref, index) => {
      const drawableCanvas = drawableCanvasRef[index].ref.current;
      const imageBase64 = drawableCanvas.toDataURL();
      const base64Data = imageBase64.split(",")[1];
      const imageData = Uint8Array.from(atob(base64Data), (c) =>
        c.charCodeAt(0)
      );
      canvasToArrayBuffer.push(base64Data);
    });

    const data = {
      type: "canvasToImageSync",
      canvasBuffer: canvasToArrayBuffer,
      pageScale: pageScale,
      fileId: searchParams.get("fileid"),
      fileName: searchParams.get("filename"),
      apiEndpoint: apiEndpoint,
    };

    myWorker.onmessage = function (event) {
      console.log("Received result from worker:", event.data);
      myWorker.terminate();
    };

    myWorker.postMessage(data);
  };

  /**
   * handle bubble
   *          |
   *          v
   */

  const getBubbleSurfacePos = () => {
    // <- gets the surface postion of the bubble to draw the line from and not the center
    if (mousePos.y < lastPos.y) {
      return {
        lx: lastPos.x,
        ly: lastPos.y - svgSize / 2,
        mx: mousePos.x,
        my: mousePos.y,
      };
    } else if (mousePos.y > lastPos.y) {
      return {
        lx: lastPos.x,
        ly: lastPos.y + svgSize / 2,
        mx: mousePos.x,
        my: mousePos.y,
      };
    } else if ((mousePos.y = lastPos.y)) {
      return {
        lx: lastPos.x,
        ly: lastPos.y + svgSize / 2,
        mx: mousePos.x,
        my: mousePos.y,
      };
    }
  };

  const drawWeldBubble = async () => {
    // <- draws bubble when mouse click or touch (hold)
    // const existingBubble = userBubble.find(bubble => bubble.weldName === weldName);
    const existingBubble = false;
    isDrawing = true;
    const ctx = drawableCanvasRef[currentCanvas].ref.current.getContext("2d");
    timer = isDrawing
      ? setTimeout(() => {
          if (
            mousePos.x !== 0 &&
            mousePos.y !== 0 &&
            isDrawing &&
            existingBubble
          ) {
            // moreThanOneBubble();
          } else if (mousePos.x !== 0 && mousePos.y !== 0 && isDrawing) {
            drawSvg(ctx, mousePos.x, mousePos.y, weldName, shape, svgSize);
            lastPos = { x: mousePos.x, y: mousePos.y };
            pendingLineDraw = true;
          }
        }, touchduration)
      : null;
    if (pendingLineDraw && lastPos.x !== 0) {
      isDrawing = false;
      pendingLineDraw = false;
      const { lx, ly, mx, my } = getBubbleSurfacePos();
      await drawLineOnCanvas(ctx, lx, ly, mx, my);
      weldBubbleComplete(lx, ly, mx, my, shape);
    }
  };

  const drawLineOnCanvas = async (ctx, sx, sy, ex, ey) => {
    ctx.beginPath();
    ctx.moveTo(sx, sy);
    ctx.lineTo(ex, ey);
    ctx.strokeStyle = "#ff0000";
    ctx.stroke();
    drawEndCircle(ctx, ex, ey, 2);
  };

  const drawEndCircle = (ctx, x, y, r) => {
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI);
    ctx.strokeStyle = "#ff0000";
    ctx.fillStyle = "#ff0000";
    ctx.fill();
    ctx.stroke();
  };

  const drawTextonCanvas = (ctx, text, x, y) => {
    const lineHeight = 12;

    const hasWhiteSpace = (s) => {
      return s.indexOf(" ") >= 0;
    };

    const drawText = (text, x, y) => {
      ctx.fillStyle = "red";
      ctx.font = `${textSize}px Helvetica`;
      ctx.fillText(text, x, y);
    };

    if (hasWhiteSpace(text)) {
      const lines = text.split(" ");
      let drawingFactorX;

      for (var i = 0; i < lines.length; i++) {
        if (lines[i].length > 1) {
          drawingFactorX = x - (lines[i].length + 6);
        } else {
          drawingFactorX = x - (lines[i].length + 4);
        }
        if (i === 0) {
          drawText(lines[i], drawingFactorX, y + 3);
        } else {
          drawText(lines[i], drawingFactorX, y + i * lineHeight);
        }
      }
    } else {
      drawText(text, x - (text.length + 3), y + 3);
    }
  };

  const drawSvg = (ctx, x, y, weldName, type, size) => {
    const svg = {
      pentagonSvg: PentagonSvg(size, size),
      hexagonSvg: HexagonSvg(size, size),
      circleSvg: CircleSvg(size, size),
    };
    const bubble = shape;
    const image = new Image();
    const base64 = window.btoa(svg[type]);
    image.src = `data:image/svg+xml;base64,${base64}`;
    image.onload = () => {
      ctx.drawImage(image, x - size / 2, y - size / 2);
      drawTextonCanvas(ctx, weldName, x, y);
    };
  };

  const weldBubbleComplete = async (sx, sy, ex, ey) => {
    const bubble = {
      x: lastPos.x,
      y: lastPos.y,
      lineStartX: sx,
      lineStartY: sy,
      lineEndX: ex,
      lineEndY: ey,
      weldName: weldName,
      type: shape,
      size: svgSize,
      pageNumber: currentCanvas,
      user: user,
      timeStamp: Date.now(),
    };

    startBTransition(() => {
      setUserBubble((oldArray) => [...oldArray, bubble]);
      setWeldBubble((oldArray) => [...oldArray, bubble]);
      setNewState((oldArray) => [...oldArray, bubble]);
    });

    await sync("create", bubble);
  };

  const handleShapeChange = (shape) => {
    setSelectedShape(shape);
    dispatch(setSelectedBubble(shape));
  };

  const handleTouchLock = () => {
    setIsTouchLock(!isTouchLock);
  };

  const clearPage = (ref) => {
    const context = ref.current.getContext("2d");
    context.clearRect(0, 0, ref.current.width, ref.current.height);
    setUserBubble([]);
    pendingLineDraw = false;
  };

  /**
   * API & DB
   *     |
   *     v
   */

  const initPrevWeldBubbles = (pageNumber, pageState) => {
    const drawableRef = drawableCanvasRef[pageNumber].ref;
    const ctx = drawableRef.current.getContext("2d");
    clearPage(drawableRef);
    pageState.forEach((bubble) => {
      drawSvg(
        ctx,
        bubble.x,
        bubble.y,
        bubble.weldName,
        bubble.type,
        bubble.size
      );
      drawLineOnCanvas(
        ctx,
        bubble.lineStartX,
        bubble.lineStartY,
        bubble.lineEndX,
        bubble.lineEndY
      );
    });
  };

  const initOldBubblesDb = async () => {
    const response = await getWeldBubble(searchParams.get("fileid"))
    if (response.data.length !== 0 && response.data.bubble_coordinates !== undefined) {
      const parsedBubble = JSON.parse(response.data.bubble_coordinates);
      setWeldBServer(parsedBubble);
      setWeldBubble(parsedBubble);
      parsedBubble.forEach((bubble) => {
        if (!(bubble.pageNumber > doc.pageCount)) {
          drawSvg(
            drawableCanvasRef[bubble.pageNumber].ref.current.getContext("2d"),
            bubble.x,
            bubble.y,
            bubble.weldName,
            bubble.type,
            bubble.size
          );
          drawLineOnCanvas(
            drawableCanvasRef[bubble.pageNumber].ref.current.getContext("2d"),
            bubble.lineStartX,
            bubble.lineStartY,
            bubble.lineEndX,
            bubble.lineEndY
          );
        }
      });
    }
  };

  const updateWeldDb = async (weldBubble) => {
    await updateWeldBubble(searchParams.get("fileid"), weldBubble);
  };

  const sync = async (type, bubble) => {
    if (type === "create") {
      syncBubble(bubble);
    } else {
      syncDelete(bubble);
    }
  };

  const syncBubble = (bubble) => {
    if (listening) {
      axios.post(`${apiEndpoint}events/syncbubble`, {
        fileid: searchParams.get("fileid"),
        userId: user,
        userBubble: bubble,
      });
    }
  };

  const syncDelete = (deletedWeldName) => {
    if (listening) {
      axios.post(`${apiEndpoint}events/syncdelete`, {
        fileid: searchParams.get("fileid"),
        userId: user,
        deletedWeldName: deletedWeldName,
      });
    }
  };

  /**
   * handle Modal
   *      |
   *      v
   */

  const modalYes = (type) => {
    setIsOpen(false);
  };

  const modalCancel = () => {
    setIsOpen(false);
  };

  const moreThanOneBubble = () => {
    setModalType("moreThanOneBubble");
    setIsOpen(true);
  };

  /**
   *  Handle Buttons
   *        |
   *        v
   */

  const deleteWeldBubble = (weldName, pageNumber, type) => {
    let pageState = [];
    const deletedState = bubbleRef.current.filter(
      (s) => s.weldName !== weldName
    );
    deletedState.forEach((item) => {
      if (item.pageNumber === pageNumber) {
        pageState = [...pageState, item];
      }
    });
    initPrevWeldBubbles(pageNumber, [...pageState]);
    startBTransition(() => {
      setWeldBubble(deletedState);
      setNewState(deletedState);
    });
    if (type === "userDelete") {
      sync("delete", [weldName, pageNumber]);
    }
  };

  const nextPage = async () => {
    if (pdfRef && currentPage < pdfRef.numPages) {
      setCurrentPage(currentPage + 1);
      setCurrentCanvas(currentCanvas + 1);
      reset();
    }
  };
  const prevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setCurrentCanvas(currentCanvas - 1);
      reset();
    }
  };

  /**
   * useEffect starts here
   *          |
   *          v
   */

  useEffect(() => {
    renderPdf();
  }, []);

  useEffect(() => {
    if (pdfRef) {
      renderPage(pageScale, pdfRef);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!isMountingRef.current) {
    } else {
      updateWeldDb(weldBubble);
      driveSyncWorker();
    }
  }, [newState]);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    const updateSSEState = (data) => {
      setWeldBServer((weldBServer) => weldBServer.concat(data));
      setWeldBubble((weldBubble) => weldBubble.concat(data));
    };
    if (!listening) {
      const events = new EventSource(
        `${apiEndpoint}events?userid=${user}&fileid=${searchParams.get(
          "fileid"
        )}`
      );
      events.onmessage = (event) => {
        console.log("SSE Connection Established");
        const parsedData = JSON.parse(event.data);
        if (parsedData.length !== 0) {
          if (parsedData[1] === "create") {
            [parsedData[0]].forEach((bubble) => {
              const ctx =
                drawableCanvasRef[bubble.pageNumber].ref.current.getContext(
                  "2d"
                );
              drawSvg(
                ctx,
                bubble.x,
                bubble.y,
                bubble.weldName,
                bubble.type,
                bubble.size
              );
              drawLineOnCanvas(
                ctx,
                bubble.lineStartX,
                bubble.lineStartY,
                bubble.lineEndX,
                bubble.lineEndY
              );
            });
            updateSSEState(parsedData[0]);
          } else {
            deleteWeldBubble(parsedData[0][0], parsedData[0][1], "eventDelete");
          }
        }
      };
      setListening(true);
    }
  }, [listening, weldBServer]);

  useEffect(() => {
    window.addEventListener(
      "touchmove",
      (e) => {
        touchMove(e);
      },
      { passive: false }
    );
    return () => window.removeEventListener("touchmove", touchMove);
  }, [touchMove]);

  return (
    <div className="parent">
      <div
        className="wrapper"
        style={{ touchAction: isTouchLock ? "none" : null }}
      >
        <Spinner
          style={{
            display: isPending || isBTPending ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
          }}
        />
        <canvas ref={canvasRef} className="frame" id="content" />
        {drawableCanvasRef.map(({ ref }, index) => (
          <canvas
            key={index}
            ref={ref}
            className="drawable-canvas"
            onMouseDown={(e) => {
              mouseDown(e);
            }}
            onTouchStart={(e) => {
              touchStart(e);
            }}
            onMouseUp={(e) => {
              mouseUp(e);
            }}
            onTouchEnd={(e) => {
              touchEnd(e);
            }}
            style={{ display: currentCanvas === index ? "block" : "none" }}
          />
        ))}

        <div className="listcontainer">{temp}</div>
      </div>
      {/* <div className='finalizewrapper'>

                <Modal isOpen={isOpen}
                    yes={() => modalYes(modalType)}
                    cancel={modalCancel}
                    heading={text[modalType].heading}
                    message={text[modalType].message}
                    subHeading={text[modalType].subHeading}
                    type={modalType}
                >
                </Modal>
            </div> */}
      <div>
        <ToolBar
          previousPage={prevPage}
          nextPage={nextPage}
          shapeChange={handleShapeChange}
          handleTouchLock={handleTouchLock}
          selectedShape={selectedShape}
          isTouchLock={isTouchLock}
          listObject={weldBubble}
          listOnClick={deleteWeldBubble}
          isEditing={edit}
        />
      </div>
    </div>
  );
};

export default memo(ICEditor);
