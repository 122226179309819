import React, { useEffect, useState } from 'react';
import '../styles/homeStyles.css'; 
import { useNavigate } from "react-router-dom";
import { currentUser, signInWithGooglePopup } from '../utils/firebase.utils';
import Toast from '../components/Toast';
import { getUser } from '../api/dbApi';


function Home() {
  const navigation = useNavigate();
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState()

  const signIn = async () => {
    const user = await currentUser()
    try{
      const response = await signInWithGooglePopup()
      if(response.user.email){
        const userResponse = await getUser(response.user.email)
        if (userResponse.data === 'not found'){
          renderToast("You do not have an account with Inspect Collect, download the app now to get started", 4000)
        }else{
          navigation('/profile')
        }
      }
  }catch (e){
      console.log(e)
      renderToast("Error in Sigin")
    }
  }

  const renderToast = (message, duration = 2000) => {
    setMessage(message)
    setShowToast(true); // Show the toast when component mounts
    const timeout = setTimeout(() => setShowToast(false), duration); // Hide the toast after a specified duration
    return () => clearTimeout(timeout); // Clear the timeout on unmount
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src="/ic-logo.png" alt="App Logo" className="App-logo" />
        <button className="Signin-button" onClick={signIn}>Google Sign In</button>
      </header>
      <div className='footer'>
        <p className='text'>You need to have an account with Inspect Collect mobile app.</p>
        <Toast message={message} showToast={showToast}/>
      </div>
    </div>
  );
}

export default Home;

