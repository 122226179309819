import React, { useState } from "react";
import "../styles/adminLogin.css";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../api/adminApi";

const AdminLogin = () => {
  const [userId, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigation = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginAdmin(userId, password);
      if (response.status === 200){
      const token = response.data.token
      localStorage.setItem('token', token);
        navigation('/admindashboard')
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError("User not found");
        return;
      } else if (error.response.status === 401) {
        setError("Incorrect Password");
        return
      }
      setError(error.message);
    }
  };

  return (
    <div className="Login">
      <h2>Admin Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="userid">userid</label>
          <input
            type="userid"
            id="userid"
            value={userId}
            onChange={(e) => setUserid(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;
