import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAevZOMpeZbjkiwG9d9lDpLjtpcmMqXhXE",
  authDomain: "iclogin-957e0.firebaseapp.com",
  projectId: "iclogin-957e0",
  storageBucket: "iclogin-957e0.appspot.com",
  messagingSenderId: "80020290589",
  appId: "1:80020290589:web:9b9095f4e75186e5f61af4",
  measurementId: "G-BLVLMRR31E"
};

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account"
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const currentUser = async () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        resolve(user);
      } else {
        // No user is signed in.
        resolve(null);
      }
    });
  });
};

export const logout = async () => await signOut(auth)