import React, { useState } from "react";
import '../styles/toolbar.css'
import next from "../assets/img/next.png"
import previous from "../assets/img/previous.png"
import circleImg from '../assets/img/circle.png'
import hexagonImg from '../assets/img/hexagon.png'
import pentagonImg from '../assets/img/pentagon.png'
import touchLock from '../assets/img/touch-lock.png'
import toggle from '../assets/img/toggle-toolbar.png'
import List from "./List";

const ToolBar = (props) => {
    const {
        nextPage,
        previousPage,
        shapeChange,
        selectedShape,
        isTouchLock,
        handleTouchLock,
        listObject,
        listOnClick,
        isEditing
    } = props

    const [toolbarVisible, setToolbarVisible] = useState(true);

    const toggleToolbar = () => {
        setToolbarVisible(!toolbarVisible);
    };

    return (
        <div className="outer-container">
            <div className="toggle-container">
                <img src={toggle} alt="Circle" onClick={toggleToolbar} className={"toggle-icon"} />
            </div>

            <div className="parent-container">
                <div className={`toolbar-container`}>
                    <div className="toolbar">
                        {isEditing === 'true'?
                        <div className='selector'>
                            <img src={circleImg} alt="Circle" onClick={() => shapeChange("circleSvg")} className={`svgs ${selectedShape === "circleSvg" ? "selected" : ""}`} />
                            <img src={hexagonImg} alt="Hexagon" onClick={() => shapeChange("hexagonSvg")} className={`svgs ${selectedShape === "hexagonSvg" ? "selected" : ""}`} />
                            <img src={pentagonImg} alt="Pentagon" onClick={() => shapeChange("pentagonSvg")} className={`svgs ${selectedShape === "pentagonSvg" ? "selected" : ""}`} />
                            <img src={touchLock} alt="Circle" onClick={handleTouchLock} className={`touch-icon ${isTouchLock ? "selected" : ""}`} />
                        </div> : null
                        }
                        
                        <button className="toolbar-button" onClick={previousPage}>
                            <img className="icon" src={previous}></img>
                        </button>
                        <button className="toolbar-button" onClick={nextPage}>
                            <img className="icon" src={next}></img>
                        </button>
                    </div>
                </div>
                <div className="listcontainer" style={{ display: toolbarVisible ? 'flex' : 'none', height: '255px', overflow: 'scroll'}}>
                    <List object={listObject} onButtonClick={listOnClick} />
                </div>
            </div>
        </div>
    )
}

export default ToolBar