import React, { useState } from "react";
import "../styles/adminDashboard.css"; // Import CSS file for styling
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../api/adminApi";
import Navbar from "../components/NavBar";

const AdminDashboard = () => {
  const navigation = useNavigate();
  return (
    <div>
      <Navbar />
    </div>
  );
};

export default AdminDashboard;
