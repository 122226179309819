export const PentagonSvg = (width, height) => {
        return `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="${height}" 
        preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="a3f7524344"><path d="M 0 1 L 374.703125 1 L 374.703125 375 L 0 375 Z M 0 1 " clip-rule="nonzero"/>
        </clipPath><clipPath id="d536e2d0a5"><path d="M 189.734375 2.117188 L 372.320312 141.597656 C 373.753906 142.691406 374.351562 144.5625 373.816406 146.285156 L 304.027344 
        372.132812 C 303.5 373.835938 301.925781 375 300.140625 375 L 74.5625 375 C 72.777344 375 71.203125 373.835938 70.675781 372.132812 L 0.886719 146.285156 C 0.355469 
        144.5625 0.953125 142.691406 2.382812 141.597656 L 184.96875 2.117188 C 186.375 1.042969 188.328125 1.042969 189.734375 2.117188 Z M 189.734375 2.117188 " 
        clip-rule="nonzero"/></clipPath><clipPath id="3717885f3b"><path d="M 0 1 L 374 1 L 374 374.992188 L 0 374.992188 Z M 0 1 " clip-rule="nonzero"/></clipPath><clipPath
         id="87690cc113"><path d="M 189.730469 2.117188 L 372.3125 141.597656 C 373.742188 142.691406 374.339844 144.5625 373.808594 146.28125 L 304.019531 372.125 C 
         303.492188 373.828125 301.917969 374.992188 300.136719 374.992188 L 74.558594 374.992188 C 72.777344 374.992188 71.203125 373.828125 70.675781 372.125 L 0.886719
          146.28125 C 0.355469 144.5625 0.953125 142.691406 2.382812 141.597656 L 184.964844 2.117188 C 186.371094 1.042969 188.324219 1.042969 189.730469 2.117188 Z M
           189.730469 2.117188 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#a3f7524344)"><g clip-path="url(#d536e2d0a5)"><path fill="#ffffff" d="M 0 0.296875
            L 374.703125 0.296875 L 374.703125 375 L 0 375 Z M 0 0.296875 " fill-opacity="1" fill-rule="nonzero"/></g></g><g clip-path="url(#3717885f3b)">
            <g clip-path="url(#87690cc113)"><path stroke-linecap="butt" transform="matrix(0.75, 0, 0, 0.75, 0.0000045, 0.296208)" fill="none" stroke-linejoin="miter" 
            d="M 252.973953 2.427972 L 496.416661 188.401931 C 498.322911 189.860264 499.119786 192.355056 498.411453 194.646722 L 405.35937 495.771723 C 404.656245 
            498.042556 402.557286 499.59464 400.182286 499.59464 L 99.411452 499.59464 C 97.036452 499.59464 94.937494 498.042556 94.234369 495.771723 L 1.182286 
            194.646722 C 0.473952 192.355056 1.270827 189.860264 3.177077 188.401931 L 246.619786 2.427972 C 248.494786 0.99568 251.098953 0.99568 252.973953 2.427972 Z 
            M 252.973953 2.427972 " stroke="#ff0000" stroke-width="26" stroke-opacity="1" stroke-miterlimit="4"/></g></g></svg>
        `;
}