export const CircleSvg = (width, height) => {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="${height}" 
    preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="34cd53352c"><path d="M 187.5 0 C 83.945312 0 0 83.945312 0 187.5 C 0 291.054688 83.945312 375 
    187.5 375 C 291.054688 375 375 291.054688 375 187.5 C 375 83.945312 291.054688 0 187.5 0 Z M 187.5 0 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#34cd53352c)">
    <rect x="-37.5" width="450" fill="#ffffff" y="-37.499999" height="449.999989" fill-opacity="1"/><path stroke-linecap="butt" transform="matrix(0.75, 0, 0, 0.75, 0.0000045, 
    0.000000000000042633)" fill="none" stroke-linejoin="miter" d="M 249.999994 -0.000000000000056843 C 111.927078 -0.000000000000056843 -0.000006 111.927084 -0.000006 250 
    C -0.000006 388.072917 111.927078 500.000001 249.999994 500.000001 C 388.072911 500.000001 499.999995 388.072917 499.999995 250 C 499.999995 111.927084 388.072911 
    -0.000000000000056843 249.999994 -0.000000000000056843 Z M 249.999994 -0.000000000000056843 " stroke="#ff0000" stroke-width="26" stroke-opacity="1" 
    stroke-miterlimit="4"/></g></svg>
    `
}