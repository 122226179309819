import { createSlice } from '@reduxjs/toolkit'

export const pdfDoc = createSlice({
    name: 'doc',
    initialState: {
        doc: null,
        selectedBubble: "pentagonSvg"
    },
    reducers: {
        saveDoc: (state, action) => {
            state.doc = action.payload
        },
        setSelectedBubble: (state, action) => {
            state.selectedBubble = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { saveDoc, setLoading, setSelectedBubble } = pdfDoc.actions
export default pdfDoc.reducer
