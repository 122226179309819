import React, { useState, useEffect } from 'react';
import '../styles/profileStyles.css'; // Import CSS file for styling
import { currentUser, logout } from '../utils/firebase.utils';
import { getUser } from '../api/dbApi';
import { useNavigate } from 'react-router';

const Profile = () => {
  const [fbUser, setFbUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null)
  const [createdProjects, setCreatedProjects] = useState(null)
  const navigation = useNavigate();
  // Fetch user information when the component mounts
  useEffect(() => {
    async function fetchUserData() {
      const fbUserData = await currentUser();
      if (fbUserData !== null){
        setFbUser(fbUserData);
      }else{
        navigation('/')
      }
      const userResponse = await getUser(fbUserData.email)
      setUserProfile(userResponse.data)
      const totalProjects = JSON.parse(userResponse.data.created_projects)
      setCreatedProjects(totalProjects.length)
      console.log(userResponse.data)
    }
    fetchUserData();
  }, []);

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account?')) {
    //   await deleteUser();
    }
  };

  const handleLogout = async () => {
    await logout()
    navigation('/')
  };

  return (
    <div className="Profile">
      {userProfile && (
        <div className="Profile-card">
          <h2>Account Information</h2>
          <p><strong>User Name:</strong> {fbUser.displayName}</p>
          <p><strong>Date Created:</strong> {userProfile.date_created}</p>
          <p><strong>Account Type:</strong> {userProfile.user_type}</p>
          <p><strong>Created Projects:</strong> {createdProjects}</p>
          <p><strong>Plan End:</strong> {userProfile.plan_end}</p>
          <div className="Profile-buttons">
            <button className="Profile-button" onClick={handleDeleteAccount}>Delete Account</button>
            <button className="Profile-button" onClick={handleLogout}>Log Out</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;