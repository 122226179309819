import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivacyPolicy from "./screens/privacyPolicy";
import Home from "./screens/home";
import ICEditor from "./screens/ICEditor";
import Profile from "./screens/profileScreen";
import AdminLogin from "./screens/adminLogin";
import AdminDashboard from "./screens/adminDashboard";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home/>,
    },
    {
      path: "/privacy",
      element: <PrivacyPolicy/>,
    },
    {
      path: "/iceditor",
      element: <ICEditor/>,
    },
    {
      path: "/profile",
      element: <Profile/>,
    },
    {
      path: "/admin",
      element: <AdminLogin/>,
    },
    {
      path: "/admindashboard",
      element: <AdminDashboard/>,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;

