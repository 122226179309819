import React from 'react';
import '../styles/styles.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <header>
        <h1>Privacy Policy</h1>
      </header>

      <div className="container">
        <section className="privacy-policy section">
          <p>Last Updated: 08/15/2023</p>

          <p>
            This Privacy Policy outlines the manner in which [Your Company Name]
            ("we", "us", or "our") collects, uses, maintains, and discloses information
            collected from users ("User" or "you") of the Inspect Collect mobile
            application ("App").
          </p>

          <h2>Information We Collect:</h2>
          <p>We may collect the following information from Users:</p>
          <ul>
            <li>
              Personal Identification Information: We may collect personal identification
              information such as your name and email address when you interact with the App
              or contact our support team.
            </li>
            <li>
              Usage Information: We may collect non-personal identification information about
              how you use the App, including device information, log data, and usage patterns.
              This information helps us enhance the user experience.
            </li>
          </ul>

          {/* Additional sections of your privacy policy */}

          <h2>Changes to This Privacy Policy:</h2>
          <p>
            We have the discretion to update this privacy policy at any time. When we do,
            we will revise the updated date at the top of this page. We encourage Users to
            frequently check this page for any changes and to stay informed about how we are
            helping to protect the personal information we collect.
          </p>

          <h2>Contacting Us:</h2>
          <p>
            If you have any questions about this Privacy Policy, the practices of this App,
            or your dealings with this App, please contact us at{' '}
            <a href="mailto:support@inspectcollect.com">support@inspectcollect.com</a>.
          </p>
        </section>
      </div>

      <footer>
        <p>&copy; 2023 [Your Company Name]. All rights reserved.</p>
        <div className="footer-links">
          <a href="privacy-policy.html">Privacy Policy</a>
        </div>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;

