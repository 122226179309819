import React from "react";
import '../styles/liststyles.css'

const List = (props) => {
    const { object, onButtonClick } = props
    return (
        <ul style={{display: "flex", flexDirection: 'column', padding: '20px'}}>
            {object.map((item, index) =>
                <li className="item" key={index}>
                    <div className="innercontainer">
                        <p className="text">{item.weldName} <button className="button" placeholder="Delete" onClick={() => onButtonClick(item.weldName, item.pageNumber, 'userDelete')}> Delete </button> </p> 
                    </div>
                </li>
            )}
        </ul>
    )
}

export default List