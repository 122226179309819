import React, { useState, useEffect } from "react";
import "../styles/toast.css"; // Import CSS file for toast styling

const Toast = ({ message, showToast }) => {

  return (
    <div className={`Toast ${showToast ? "show" : ""}`}>
      <div className="Toast-message">{message}</div>
    </div>
  );
};

export default Toast;
